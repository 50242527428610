var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row position-relative",class:_vm.RoutePage == 'QuizFinal'
      ? 'm-0 p-0 w-100 big-div-quiz'
      : ' w-85 big-div',staticStyle:{"z-index":"100"},style:(_vm.getPageDesign())},[(_vm.pageDesign.pageStyles.imageAlign == 'no')?_c('div',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),staticClass:"bg lazyload",style:(_vm.getbackgroundImage(
        _vm.IsImageAdded,
        _vm.pageDesign.imageBlock,
        _vm.pageDesign.pageStyles.imageAlign,
        _vm.isDesktopView
      ) + _vm.getBgDivStyles()),attrs:{"data-bg":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView))}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftImage),expression:"showLeftImage"}],staticClass:"px-0 px-0",class:[
      {
        'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
        'image-division col-6': _vm.isDesktopView,
      },
    ],style:(_vm.calculateImageWidth(
        _vm.pageDesign.imageBlock,
        _vm.isDesktopView,
        _vm.IsImageVariableWidthActive
      ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
            `pic-${_vm.getSelectedPage.order}`,
            _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
            '/images/default_start_page.png'
              ? 'default-bg'
              : '',
          ],style:(_vm.getUniversalImageStyle(
              _vm.isDesktopView,
              _vm.pageDesign.imageBlock.style,
              _vm.IsImageVariableWidthActive,
              _vm.pageDesign.imageBlock
            ) +
            _vm.getImageRotation(_vm.pageDesign.imageBlock.style) +
            _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)])]),_c('div',{staticClass:"",class:[!_vm.IsImageAdded ? 'col-12' : _vm.imageAddedLayout],style:(_vm.getMainPaddingCss(_vm.pageDesign.pageStyles, _vm.isDesktopView) +
      _vm.getScrollCSS(
        _vm.pageDesign.pageStyles,
        _vm.isDesktopView,
        _vm.pageDesign.imageBlock.style
      ) +
      _vm.calculateContentWidth(
        _vm.pageDesign.imageBlock,
        _vm.isDesktopView,
        _vm.IsImageVariableWidthActive,
        _vm.pageDesign.pageStyles
      ))},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"flex-end","height":"100%"}},[_c('div',{staticClass:"h-100",staticStyle:{"overflow-y":"scroll"}},_vm._l((_vm.pageDesign.blocksArray),function(pro,ind){return _c('div',{key:'preview' + ind,class:`item-${ind}`,attrs:{"data-aos":_vm.animationType,"data-aos-duration":_vm.getAnimationSpeedinMillis,"data-aos-delay":_vm.AOSAnimationDelay(ind),"data-aos-anchor":`.item-${ind == 0 ? ind : ind - 1}`}},[_c('div',{staticClass:"content",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style)),attrs:{"id":"preview"}},[(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
                _vm.getPaddingCss(pro.style) +
                _vm.getMarginCss(pro.style, _vm.isDesktopView) +
                _vm.getColorWithOpacity(pro.style) +
                _vm.getTextRotate(pro.style) +
                _vm.getLineHeight(pro.style) +
                _vm.getTextAlign(pro.style) +
                _vm.getFontStyle(pro.style,_vm.isDesktopView)),domProps:{"innerHTML":_vm._s(pro.content)}}):_vm._e(),(pro.type == 'tooltip')?_c('div',{style:(`display:flex;justify-content: ${pro.style.position} ;  `)},[_c('button',{style:(_vm.getButtonCss(pro.style, _vm.isDesktopView) +
                  _vm.getCircularBtn(pro.isBtnRounded)),on:{"mouseenter":function($event){pro.isTooltipVisible = true},"mouseleave":function($event){pro.isTooltipVisible = false}}},[(pro.useIcon)?_c('span',[_c('BIcon',{attrs:{"icon":pro.selectedIcon}})],1):_c('span',{style:({
                    opacity: `${pro.style.textOpacity}%`,
                    fontSize: `${pro.style.fontSize}px`,
                  })},[_vm._v(" "+_vm._s(pro.text)+" ")])]),(pro.isTooltipVisible)?_c('ToolTipComp',{attrs:{"tooltipData":pro,"isDesktopView":_vm.isDesktopView}}):_vm._e()],1):_vm._e(),(pro.type == 'html')?_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
                _vm.getColorWithOpacity(pro.style) +
                _vm.getTextRotate(pro.style) +
                _vm.getTextAlign(pro.style))},[_c('HtmlElement',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e(),(pro.type == 'sliderRangeInput')?_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView))},[_c('SliderInputComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView,"isEditor":true},on:{"updateSliderValue":function($event){return _vm.updateSliderValue($event)}}})],1):_vm._e()])])}),0),_c('div',[_c('div',{staticClass:"content",style:(_vm.getHeightWidth(_vm.StaticButtonElement.style))},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex",style:(_vm.getBackNextButtonPosition(
                  _vm.StaticButtonElement,
                  _vm.CurrentPage.value
                ))},[(!_vm.StaticButtonElement.content.backButton.hideBackButton)?_c('button',{staticClass:"ButtonHover",staticStyle:{"z-index":"1000"},style:(_vm.getCommonButtonCSS(
                    _vm.StaticButtonElement.style,
                    _vm.isDesktopView
                  ) +
                  _vm.getBackNextButtonCss(
                    _vm.StaticButtonElement.content.backButton.style,_vm.isDesktopView
                  )),on:{"click":_vm.backPage}},[_c('span',{style:({
                    opacity: `${_vm.StaticButtonElement.content.backButton.style.textOpacity}%`,
                    fontSize: `${_vm.StaticButtonElement.content.backButton.style.fontSize}px`,
                  })},[_vm._v(_vm._s(_vm.StaticButtonElement.content.backButton.text))])]):_vm._e(),_c('button',{staticClass:"ButtonHover",staticStyle:{"z-index":"1000"},style:(_vm.getCommonButtonCSS(
                    _vm.StaticButtonElement.style,
                    _vm.isDesktopView
                  ) +
                  _vm.getBackNextButtonCss(
                    _vm.StaticButtonElement.content.nextButton.style,_vm.isDesktopView
                  )),on:{"click":function($event){return _vm.initSliderValue()}}},[_c('span',{style:({
                    opacity: `${_vm.StaticButtonElement.content.nextButton.style.textOpacity}%`,
                    fontSize: `${_vm.StaticButtonElement.content.nextButton.style.fontSize}px`,
                  })},[_vm._v(_vm._s(_vm.StaticButtonElement.content.nextButton.text))])])])])])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightImage),expression:"showRightImage"}],staticClass:"px-0 px-0",class:[
      {
        'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
        'image-division col-6': _vm.isDesktopView,
      },
    ],style:(_vm.calculateImageWidth(
        _vm.pageDesign.imageBlock,
        _vm.isDesktopView,
        _vm.IsImageVariableWidthActive
      ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
            `pic-${_vm.getSelectedPage.order}`,
            _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
            '/images/default_start_page.png'
              ? 'default-bg'
              : '',
          ],style:(_vm.getUniversalImageStyle(
              _vm.isDesktopView,
              _vm.pageDesign.imageBlock.style,
              _vm.IsImageVariableWidthActive,
              _vm.pageDesign.imageBlock
            ) +
            _vm.getImageOpacity(_vm.pageDesign.imageBlock.style) +
            _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }